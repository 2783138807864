import { formatDate } from "date-fns";
import { ja as localeJa } from "date-fns/locale";

import backendApi, { ErrorResponseException } from "~/libs/backendApi";
import { DriverType } from "~/libs/constants";
import logger from "~/libs/logger";
import { getCurrentDateTimeOnJst } from "~/libs/utils";
import { initDeliveryRecords } from "~/libs/syncOperationState";

const workManage = {
  /**
   * 業務開始
   * @param {import("~/libs/commonTypes").UserContext} userContext
   * @param {DriverType} driverType
   */
  async begin(userContext, driverType) {
    try {
      await backendApi.beginWork({
        driverType,
        startAt: formatDate(getCurrentDateTimeOnJst(), "yyyy-MM-dd HH:mm:ss", {
          locale: localeJa,
        }),
      });
    } catch (error) {
      if (
        error instanceof ErrorResponseException &&
        error.errorResponse.title === "alreadyBeenStarted"
      ) {
        // 業務開始日時が既に登録されているエラーは特に問題無し
      } else {
        // サーバーエラー応答等が発生した場合、エラーログを出して継続
        logger.error(
          "[workManage] 業務開始記録でエラーが発生しました",
          {
            username: userContext.loginUser?.username,
          },
          error,
        );
      }
    } finally {
      if (driverType === DriverType.DRIVER) {
        userContext.duringDriverWorking = true;
        const currentDate = formatDate(
          getCurrentDateTimeOnJst(),
          "yyyy-MM-dd",
          {
            locale: localeJa,
          },
        );
        if (currentDate !== userContext.deliveryRecordsDate) {
          // 現在日付が前回の配送記録日付と異なる場合、配送記録を初期化
          userContext.deliveryRecordsDate = currentDate;
          await initDeliveryRecords(userContext);
        }
      } else {
        userContext.duringCoreDeliveryWorking = true;
      }
      userContext.store();
    }
  },

  /**
   * 業務終了
   * @param {import("~/libs/commonTypes").UserContext} userContext
   * @param {DriverType} driverType
   */
  async finish(userContext, driverType) {
    try {
      await backendApi.finishWork({
        driverType: driverType,
        endAt: formatDate(getCurrentDateTimeOnJst(), "yyyy-MM-dd HH:mm:ss", {
          locale: localeJa,
        }),
      });
    } catch (error) {
      // サーバーエラー応答等が発生した場合、エラーログを出して継続
      logger.error(
        "[workManage] 業務終了記録でエラーが発生しました",
        {
          username: userContext.loginUser?.username,
        },
        error,
      );
    } finally {
      if (driverType === DriverType.DRIVER) {
        userContext.duringDriverWorking = false;
        userContext.deliveryList = undefined;
      } else {
        userContext.duringCoreDeliveryWorking = false;
        userContext.inTransitDeliveryList = undefined;
      }
      userContext.store();
    }
  },
};

export default Object.freeze(workManage);
