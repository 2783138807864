import imageCompression from "browser-image-compression";

import { DropOffLocation } from "~/libs/constants";
import logger from "~/libs/logger";

const imageFormatUtil = {
  /**
   * 画像のMIMEタイプがimage/jpeg以外の場合にimage/jpegに変換し、変換後の画像を返す
   * 変換できない形式の場合はnullを返す
   * @param {File} photoFile
   * @returns {Promise<File>}
   */
  async formatToJpeg(photoFile) {
    try {
      if (photoFile?.type !== "image/jpeg") {
        const options = {
          fileType: "image/jpeg",
        };
        await imageCompression(photoFile, options).then(
          (img) => (photoFile = img),
        );
      }
    } catch (error) {
      logger.error(
        "[imageFormatUtil] 画像フォーマットの変換時にエラーが発生しました",
        {
          name: photoFile?.name,
          type: photoFile?.type,
          size: photoFile?.size,
        },
        error,
      );
      photoFile = null;
    }

    return photoFile;
  },

  /**
   * 指定された画像が置き配の写真だった場合にimage/jpegに変換し、変換後の画像を返す
   * @param {File} photoFileForProofOfDelivery
   * @param {number} actualPackageDropPlace
   * @returns {Promise<File>}
   */
  async proofOfDeliveryPhotoChange(
    photoFileForProofOfDelivery,
    actualPackageDropPlace,
  ) {
    if (
      [
        /** @type {number} */ (DropOffLocation.BY_THE_DOOR),
        DropOffLocation.LOCKER,
        DropOffLocation.METERBOX,
        DropOffLocation.MAILBOX,
      ].includes(actualPackageDropPlace)
    ) {
      // 置き配の写真の場合
      photoFileForProofOfDelivery = await imageFormatUtil.formatToJpeg(
        photoFileForProofOfDelivery,
      );
    }

    return photoFileForProofOfDelivery;
  },
};

export default Object.freeze(imageFormatUtil);
