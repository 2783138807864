<script>
  import Button from "@smui/button";
  import Checkbox from "@smui/checkbox";
  import { Label } from "@smui/common";
  import FormField from "@smui/form-field";
  import IconButton from "@smui/icon-button";
  import SegmentedButton, { Segment } from "@smui/segmented-button";
  import Switch from "@smui/switch";
  import Tooltip, { Wrapper } from "@smui/tooltip";
  import Accordion, {
    Header as AccordionHeader,
    Content,
    Panel,
  } from "@smui-extra/accordion";
  import { HTTPError } from "ky";
  import { getContext, onDestroy, onMount, tick } from "svelte";
  import { fade } from "svelte/transition";
  import { _ } from "svelte-i18n";

  import Badge from "~/components/Badge.svelte";
  import ConfirmDialog from "~/components/ConfirmDialog.svelte";
  import Footer from "~/components/Footer.svelte";
  import Header from "~/components/Header.svelte";
  import HelpBase from "~/components/help/HelpBase.svelte";
  import HelpIndividualScan from "~/components/help/HelpIndividualScan.svelte";
  import QrCodeScanner from "~/components/QrCodeScanner.svelte";
  import RoleIcon from "~/components/RoleIcon.svelte";
  import addressUtils from "~/libs/addressUtils";
  import { beep, destroyAudioContext } from "~/libs/audio";
  import backendApi, { OfflineException } from "~/libs/backendApi";
  import {
    CONTEXT_KEY_APP,
    CONTEXT_KEY_USER,
    ConfirmDialogTypes,
    NotificationCategory,
    QrHomeTypes,
    QrScanModes,
    STATUS_CREATED,
    STATUS_IN_TRANSIT,
  } from "~/libs/constants";
  import depotLocations from "~/libs/depotLocations";
  import inTransitDeliveryListUtils from "~/libs/inTransitDeliveryListUtils";
  import loadingProgress from "~/libs/loadingProgress";
  import logger from "~/libs/logger";
  import notificationHistoryUtils from "~/libs/notificationHistoryUtils";
  import pageRouter from "~/libs/pageRouter";
  import { CodeType } from "~/libs/qrCodeScanner";
  import { updateCenter } from "~/libs/stores";
  import { reserveUpdateDeliveryRecordsAndSyncBackend } from "~/libs/syncOperationState";
  import { toast } from "~/libs/toast";
  import {
    formatTrackingNumber,
    parseCodabarEmbeddedTrackingNumber,
    parseQRCodeEmbeddedTrackingNumber,
  } from "~/libs/trackingNumberUtils";

  /** @type {import("~/libs/commonTypes").AppContext} */
  const appContext = getContext(CONTEXT_KEY_APP);

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  const centerId = $updateCenter ? $updateCenter.split("/")[0] : null;
  const centerName = $updateCenter ? $updateCenter.split("/")[1] : null;

  /** @type {Set<string>} */
  const decodedTextList = new Set();

  /** @type {QrCodeScanner} */
  let qrCodeScanner;

  let listContents;
  /**
   * @type {Array<{
   *   trackingNumber: string,
   *   numberOfPackages: number,
   *   locationShortName: string,
   *   address: string,
   *   correctedAddress?: string,
   *   receiverName: string,
   *   damaged: boolean,
   *   cubicSize: number,
   *   isManuallyInputted: boolean,
   *   multipleBoxesChecked: boolean,
   *   returnReason?: number,
   * }>} */
  let trackingList = [];
  let num = 0;
  let badgeNum;
  let choices = [100, 140, 160];
  /** 持出更新処理中フラグ */
  let updateInProgress = false;
  let badge;

  /** 戻る確認ダイアログ @type {ConfirmDialog} */
  let backConfirmDialog;

  /** 輸送先のセンターID @type {number} */
  let destinationCenterId;

  /** 配送センターIDをキーとしたセンター情報のMap @type {Map<number, import("~/libs/commonTypes").DepotLocation>} */
  let centersMap;

  /** データ破棄確認後に実行する処理 @type {() => void} */
  let functionAfterDiscard;

  /** コードスキャンおよびスキャン後の後処理を実行している数（実行中の「次へ」ボタンの制御に使用） */
  let numberOfScanInProgress = 0;

  /** 各荷物の確認事項（返品対象、複数個口）が全て確認済みかどうか @type {boolean}*/
  let allChecked = true;

  /** @type {import("svelte").ComponentType<HelpBase>} */
  let helpBase;

  /** @type {import("svelte").ComponentType<HelpIndividualScan>} */
  let helpContents;

  // ページの初期化処理（非同期）
  (async () => {
    centersMap = await depotLocations.getCentersMap();

    // 初めての画面表示時にヘルプを表示
    if (!appContext.firstPickupAndSortWithQrOpened) {
      helpContents = HelpIndividualScan;
      helpBase = HelpBase;
      appContext.firstPickupAndSortWithQrOpened = true;
      appContext.store();
    }
  })();

  onMount(
    loadingProgress.wrapAsync(async () => {
      await qrCodeScanner.startScanning();
    }),
  );

  onDestroy(() => {
    // AudioContextを破棄（iOSはAudioContextを破棄しないと次回起動時に音が鳴らなくなる）
    destroyAudioContext();
  });

  /**
   * @param {string} decodedText
   * @param {boolean} needsDecode
   * @param {import("~/libs/qrCodeScanner").CodeType} codeType
   */
  async function onScanSuccess(decodedText, needsDecode, codeType) {
    let isManuallyInputted = false;
    const currentDecodedTextListSize = decodedTextList.size;
    badgeNum = trackingList.length;

    /** @type {Error} */
    let parseError;
    try {
      if (needsDecode) {
        decodedText =
          codeType === CodeType.QRCODE
            ? parseQRCodeEmbeddedTrackingNumber(decodedText)
            : parseCodabarEmbeddedTrackingNumber(decodedText);
      } else {
        isManuallyInputted = true;
      }
    } catch (error) {
      parseError = error;
    } finally {
      decodedTextList.add(decodedText);
    }

    if (decodedTextList.size === currentDecodedTextListSize) {
      // スキャン済みのデータは無視
      return;
    } else if (parseError) {
      toast.error(parseError.message);
      return;
    }

    numberOfScanInProgress++;
    try {
      beep();
      let trackingData = {
        trackingNumber: formatTrackingNumber(decodedText),
        numberOfPackages: 1,
        locationShortName: "取得中...",
        address: "取得中...",
        receiverName: "取得中...",
        damaged: false,
        cubicSize: 100,
        isManuallyInputted: isManuallyInputted,
        multipleBoxesChecked: true,
      };
      trackingList.push(trackingData);
      trackingList = trackingList;
      badge = null;
      num = num + 1;
      badgeNum = num;
      badge = Badge;
      await tick();
      let listHeight = listContents.scrollHeight;
      listContents.scrollTop = listHeight;
      await execAddressApi(decodedText);
    } finally {
      numberOfScanInProgress--;
    }
  }

  /**
   * @param {string} trackingNumber
   */
  async function execAddressApi(trackingNumber) {
    try {
      const qrScanResponse = await backendApi.getShipmentInfoByQrScan(
        trackingNumber,
        QrScanModes.PICKUP_AND_SORT,
      );
      onReceiveAddress(trackingNumber, { qrScanResponse: qrScanResponse });
    } catch (error) {
      logger.error(
        "[PickupAndSortWithQrCodeScan] QRコードスキャンAPIの呼出・データ統合処理でエラーが発生しました",
        {
          username: userContext.loginUser?.username,
          locationId: centerId,
          trackingNumber: trackingNumber,
        },
        error,
      );
      onReceiveAddress(trackingNumber, { error });
    }
  }

  /**
   * @param {string} trackingNumber
   * @param {{
   *   qrScanResponse?: import("~/libs/backendApi").QrScanResponse,
   *   error?: Error,
   * }} result
   */
  function onReceiveAddress(trackingNumber, { qrScanResponse, error }) {
    let targetTrackingNumber = formatTrackingNumber(trackingNumber);
    let errorStatus;
    if (error instanceof HTTPError) {
      errorStatus = error.response.status;
    }
    for (let i = 0; i < trackingList.length; i++) {
      if (trackingList[i].trackingNumber == targetTrackingNumber) {
        if (!error) {
          // Contextの輸送中荷物リストに含まれているかの確認
          const inTransitDeliveryList = userContext.inTransitDeliveryList ?? [];
          let isInTransitDeliveryList = false;

          for (let i = 0; i < inTransitDeliveryList.length; i++) {
            for (
              let j = 0;
              j < inTransitDeliveryList[i].deliveryInfoList.length;
              j++
            ) {
              for (
                let k = 0;
                k <
                inTransitDeliveryList[i].deliveryInfoList[j].basketCarList
                  .length;
                k++
              ) {
                for (
                  let l = 0;
                  l <
                  inTransitDeliveryList[i].deliveryInfoList[j].basketCarList[k]
                    .v.length;
                  l++
                ) {
                  if (
                    trackingNumber ===
                    inTransitDeliveryList[i].deliveryInfoList[j].basketCarList[
                      k
                    ].v[l].trackingNumber
                  ) {
                    isInTransitDeliveryList = true;
                    break;
                  }
                }
                if (isInTransitDeliveryList) {
                  break;
                }
              }
              if (isInTransitDeliveryList) {
                break;
              }
            }
            if (isInTransitDeliveryList) {
              break;
            }
          }

          // レスポンスをチェック
          if (!Number.isInteger(qrScanResponse.relayLocationId)) {
            // 配送エリア外の場合
            toast.error(
              $_("errors.outsideDeliveryArea", {
                values: { trackingNumber: targetTrackingNumber },
              }),
              { popsWhenPageMoved: true },
            );
            notificationHistoryUtils.deleteAndAddHistory(
              userContext.loginUser.username,
              NotificationCategory.ERROR,
              $_("errors.outsideDeliveryArea", {
                values: { trackingNumber: targetTrackingNumber },
              }),
            );
            removeFromTrackingList(i);
            return;
          } else if (Number.isInteger(qrScanResponse.returnStatus)) {
            // 返品対象の場合
            trackingList[i].returnReason = qrScanResponse.returnReason;
            allChecked = false;
          } else if (
            qrScanResponse.status == STATUS_IN_TRANSIT ||
            isInTransitDeliveryList
          ) {
            // 荷受け済みの場合
            toast.error(
              $_("errors.alreadyInTransit", {
                values: { trackingNumber: targetTrackingNumber },
              }),
              { popsWhenPageMoved: true },
            );
            removeFromTrackingList(i);
            return;
          } else if (qrScanResponse.status != STATUS_CREATED) {
            // 荷受け待ちではない（宅配パートナーに引き渡し済み）の場合
            toast.error(
              $_("errors.unableInTransit", {
                values: { trackingNumber: targetTrackingNumber },
              }),
              { popsWhenPageMoved: true },
            );
            removeFromTrackingList(i);
            return;
          }

          // 中継配送センターをチェック
          if (!Number.isInteger(destinationCenterId)) {
            // スキャンを開始して1つ目の荷物の場合は中継配送センターを設定
            destinationCenterId = qrScanResponse.relayLocationId;
            console.log(destinationCenterId);
          } else if (destinationCenterId !== qrScanResponse.relayLocationId) {
            // スキャン済みの荷物と中継配送センターが異なる場合はエラー
            toast.error(
              $_("errors.differentDestinationCenter", {
                values: { trackingNumber: targetTrackingNumber },
              }),
              { popsWhenPageMoved: true },
            );
            removeFromTrackingList(i);
            return;
          }

          // エラー応答でない場合は取得結果を画面反映
          trackingList[i].locationShortName = centersMap.get(
            qrScanResponse.relayLocationId,
          ).locationShortName;
          trackingList[i].address = qrScanResponse.address;
          trackingList[i].correctedAddress =
            qrScanResponse.correctedReceiverAddress;
          trackingList[i].receiverName = qrScanResponse.receiverName;
          trackingList[i].numberOfPackages = qrScanResponse.numberOfPackages;
          if (qrScanResponse.numberOfPackages > 1) {
            // 複数個口の場合
            trackingList[i].multipleBoxesChecked = false;
            allChecked = false;
          }
          trackingList = trackingList;
        } else if (errorStatus == 400) {
          toast.error(
            $_("errors.unknownTrackingNumber", {
              values: { trackingNumber: targetTrackingNumber },
            }),
            { popsWhenPageMoved: true },
          );
          removeFromTrackingList(i);
        } else if (errorStatus == 401) {
          toast.error($_("errors.unauthorized"));
          logout();
        } else if (errorStatus == 403) {
          toast.error($_("errors.forbidden"));
          logout();
        } else {
          toast.error(
            $_("errors.getPackageInfoFailed", {
              values: { trackingNumber: targetTrackingNumber },
            }),
            { popsWhenPageMoved: true },
          );
          trackingList[i].locationShortName = "取得失敗";
          trackingList[i].address = "取得失敗";
          trackingList = trackingList;
        }
        break;
      }
    }
  }

  /**
   * スキャン済みリストから、指定されたインデックスの要素を削除します。
   * @param {number} index
   */
  function removeFromTrackingList(index) {
    trackingList.splice(index, 1);
    trackingList = trackingList;
    num = num - 1;
    badgeNum = num;
    if (badgeNum === 0) {
      badge = null;
      destinationCenterId = null;
    }
  }

  function onScanError(errorMessage, error) {
    console.log(
      "[PickupAndSortWithQrCodeScan.svelte] onScanError:",
      errorMessage,
      error,
    );
  }

  async function logout() {
    pageRouter.moveToLogin();
  }

  async function updateStatuses() {
    updateInProgress = true;
    try {
      if (trackingList.length) {
        // 現在時刻を保持
        const requestedTimeStamp = Date.now();
        const data = await execStatusUpdateApi(requestedTimeStamp);
        if (data && data.updateFailed) {
          const updateFailedNumbers = data.updateFailed;
          const outputTrackingNumbers = updateFailedNumbers
            .map((t) => formatTrackingNumber(t))
            .join(", ");
          toast.error(
            $_("errors.unableInTransit", {
              values: { trackingNumber: outputTrackingNumbers },
            }),
          );
          notificationHistoryUtils.deleteAndAddHistory(
            userContext.loginUser.username,
            NotificationCategory.ERROR,
            $_("errors.unableInTransit", {
              values: { trackingNumber: outputTrackingNumbers },
            }),
          );
        }
        if (data.success) {
          // Contextの輸送中荷物情報を更新
          updateInTransitDeliveryList(data.success);
          toast.info($_("message.updateCompleteInTransit"));

          // ドライバーの稼働状況をシステムに同期
          reserveUpdateDeliveryRecordsAndSyncBackend(
            userContext,
            requestedTimeStamp,
          );
        }
      }
      num = 0;
      decodedTextList.clear();

      pageRouter.moveToQrHome(QrHomeTypes.PICKUP_AND_SORT);
    } catch (error) {
      if (
        error instanceof HTTPError &&
        error.response &&
        error.response.status == 401
      ) {
        toast.error($_("errors.unauthorized"));
        stop();
        logout();
      } else if (
        error instanceof HTTPError &&
        error.response &&
        error.response.status == 403
      ) {
        toast.error($_("errors.forbidden"));
        stop();
        logout();
      } else {
        // オフライン状態やサーバーエラー応答等が発生した場合
        logger.error(
          "[PickupAndSortWithQrCodeScan] 荷受登録でエラーが発生しました",
          {
            username: userContext.loginUser?.username,
            locationId: centerId,
            trackingList: trackingList.map((e) => ({
              trackingNumber: e.trackingNumber,
              damaged: e.damaged,
              cubicSize: e.cubicSize,
              isManuallyInputted: e.isManuallyInputted,
            })),
          },
          error,
        );
        // 閉塞フラグを確認し、オフラインモード切替えが可能かを判定
        if (
          import.meta.env.VITE_DISABLED_OFFLINE_MODE_PICKUP_AND_SORT !== "true"
        ) {
          // オフラインモード切替えが可能な場合、オフラインモード切替えヘルプを表示
          if (error instanceof OfflineException) {
            toast.recommendOfflineMode($_("errors.offline"));
          } else {
            toast.recommendOfflineMode($_("errors.defaultMessage"));
          }
        } else {
          // オフラインモード切替えが不可の場合、エラーメッセージのみ表示
          if (error instanceof OfflineException) {
            toast.error($_("errors.offline"));
          } else {
            toast.error($_("errors.defaultMessage"));
          }
        }
      }
    } finally {
      updateInProgress = false;
    }
  }

  /**
   * ステータス更新APIを実行
   * @param {number} requestedTimeStamp リクエスト時刻
   * @returns {Promise<import("~/libs/backendApi").UpdateShipmentStatusResponse>}
   */
  async function execStatusUpdateApi(requestedTimeStamp) {
    let body = new FormData();
    let statusUpdateEvent = { response: true };
    let events = [];
    let updateStatus = STATUS_IN_TRANSIT;
    await trackingList.forEach((item) => {
      let event = {
        trackingNumber: item.trackingNumber.split("-").join(""),
        status: new Number(updateStatus),
        locationId: centerId !== null ? new Number(centerId) : null,
        damaged: item.damaged,
        cubicSize: item.cubicSize,
      };
      if (item.isManuallyInputted) {
        event.isManuallyInputted = item.isManuallyInputted;
      }
      events.push(event);
    });
    statusUpdateEvent["events"] = events;
    let blob2 = new Blob([JSON.stringify(statusUpdateEvent)], {
      type: "application/json",
    });
    body.append("status-update-event", blob2);

    return backendApi.updateShipmentStatus(body, requestedTimeStamp);
  }

  /**
   * 輸送中荷物リストを更新します。
   * @param {Array<import("~/libs/commonTypes").DeliveryPackage>} successList
   */
  function updateInTransitDeliveryList(successList) {
    /** @type {Array<{trackingNumber: string, quantity: number}>} 荷受け登録に成功した送り状番号と個口数のリスト */
    let trackingNumberAndQuantityList = [];
    const inTransitDeliveryList = userContext.inTransitDeliveryList ?? [];

    // 荷受け成功した送り状番号と個口数のリストを取得
    successList.forEach((element) => {
      trackingNumberAndQuantityList.push({
        trackingNumber: element.trackingNumber,
        quantity: element.numberOfPackages,
      });
    });

    // 輸送中荷物リストを更新
    inTransitDeliveryListUtils.add(
      inTransitDeliveryList,
      Number(centerId.split("/")[0]),
      destinationCenterId,
      trackingNumberAndQuantityList,
    );

    userContext.inTransitDeliveryList = inTransitDeliveryList;
    userContext.store();
  }

  function goToBackPage() {
    num = 0;
    decodedTextList.clear();
    pageRouter.moveToQrHome(QrHomeTypes.PICKUP_AND_SORT);
  }

  /** 戻るボタン押下時の処理 */
  function confirmDiscardAndGoBack() {
    if (trackingList.length > 0) {
      functionAfterDiscard = goToBackPage;
      backConfirmDialog.openDialog();
    } else {
      goToBackPage();
    }
  }

  /**
   * フッタボタン押下時の処理
   * @param {() => void} pageTransition
   */
  function confirmDiscardAndChangePage(pageTransition) {
    if (trackingList.length > 0) {
      functionAfterDiscard = pageTransition;
      backConfirmDialog.openDialog();
    } else {
      pageTransition();
    }
  }

  /**
   * 各荷物の確認事項（返品対象、複数個口）が全て確認済みかどうかをチェックする
   */
  function checkAllCheckpoints() {
    allChecked = true;
    for (let i = 0; i < trackingList.length; i++) {
      if (
        !trackingList[i].multipleBoxesChecked ||
        Number.isInteger(trackingList[i].returnReason)
      ) {
        allChecked = false;
      }
    }
  }

  /**
   * ヘルプを閉じる
   */
  function clickConfirm() {
    helpBase = null;
    helpContents = null;
  }
</script>

<!-- ヘルプ表示 -->
<svelte:component this={helpBase} {helpContents} {clickConfirm} />

<div class="mainContentsWrapper">
  <Header>
    <svelte:fragment slot="left">
      {#if userContext.canSwitchRole()}
        <RoleIcon />
      {/if}
    </svelte:fragment>
    <svelte:fragment slot="center">
      <Wrapper rich>
        <span tabindex="0" role="button">荷受け登録</span>
        <IconButton
          size="button"
          class="material-icons"
          style="vertical-align: sub;">warehouse</IconButton
        >
        {#if centerName}
          <Tooltip xPos="start" unbounded>{centerName}</Tooltip>
        {/if}
      </Wrapper>
    </svelte:fragment>
  </Header>

  <main in:fade>
    <!-- QRコードリーダ画面 -->
    <QrCodeScanner
      bind:this={qrCodeScanner}
      onScanSuccessHandler={onScanSuccess}
      onScanErrorHandler={onScanError}
      enableInputForm={true}
    />

    <!-- リスト画面 -->
    {#if trackingList.length}
      <div class="listContents" bind:this={listContents}>
        {#if Number.isInteger(destinationCenterId)}
          <div class="destinationCenterName">
            {trackingList[0].locationShortName}
          </div>
        {/if}
        <div class="accordion-container">
          <Accordion multiple>
            {#each trackingList as item (item.trackingNumber)}
              <Panel>
                <div
                  class="listItem"
                  class:listItem-error={Number.isInteger(item.returnReason)}
                >
                  <p class="number">
                    <!-- 複数個口の場合は個数を表示-->
                    {item.trackingNumber}{#if item.numberOfPackages > 1}<span
                        class="quantity">（全{item.numberOfPackages}個）</span
                      >{/if}
                  </p>
                  <div class="button">
                    <AccordionHeader
                      style="width: fit-content; border-radius: 20px;"
                    >
                      <button class="edit_button">
                        <div style="display: inline-block; width: 20px;">
                          <span class="material-icons md-18">edit</span>
                        </div>
                        <div
                          class="icons"
                          style="display: inline-block; width: 18px; margin-left: -8px; margin-right: 2px;"
                        >
                          <span class="material-icons" style="font-size: 18px;"
                            >expand_more</span
                          >
                        </div>
                      </button>
                    </AccordionHeader>
                  </div>
                  {#if Number.isInteger(item.returnReason)}
                    <!-- 返品対象の場合 -->
                    <div class="warningText">
                      この荷物は返品対象のため、荷受け登録ができません。荷物を発送元へ引き渡し、返品済みとして登録してください。<br
                      />
                      (返品理由：{$_(
                        `classes.returnReason.${item.returnReason}`,
                      )})
                      <div class="deleteButtonArea">
                        <Button
                          variant="unelevated"
                          on:click={() => {
                            const i = trackingList.findIndex(
                              (e) => e.trackingNumber === item.trackingNumber,
                            );
                            if (i !== -1) {
                              trackingList.splice(i, 1);
                              trackingList = trackingList;
                              num = num - 1;
                              badgeNum = num;
                            }
                            checkAllCheckpoints();
                          }}>OK</Button
                        >
                      </div>
                    </div>
                  {/if}
                  {#if item.numberOfPackages > 1}
                    <!-- 複数個口の場合 -->
                    <div class="warningText">
                      <FormField>
                        <Checkbox
                          bind:checked={item.multipleBoxesChecked}
                          on:change={checkAllCheckpoints}
                        />
                        <span slot="label"
                          >荷物が{item.numberOfPackages}個あることを確認した</span
                        >
                      </FormField>
                    </div>
                  {/if}
                </div>
                <Content style="padding: 0; background-color: #d9d9d950;">
                  <div class="edit_area">
                    <div class="edit_grid_area">
                      <div class="receiverInfo">
                        <p>
                          {#if item.correctedAddress}
                            <span class="highlight">
                              {addressUtils.trimPrefecture(
                                item.correctedAddress,
                              )}
                              <span class="note">※訂正された住所</span>
                            </span>
                          {:else}
                            {item.address}
                          {/if}
                        </p>
                        <p>
                          {item.receiverName}
                        </p>
                      </div>
                      <div class="edit_dameged">
                        <FormField>
                          <p>汚損</p>
                          <Switch bind:checked={item.damaged} icons={false} />
                        </FormField>
                      </div>
                    </div>
                    <SegmentedButton
                      segments={choices}
                      let:segment
                      singleSelect
                      bind:selected={item.cubicSize}
                      style="display: flex; justify-content: center; margin-bottom: 10px;"
                    >
                      <Segment {segment}>
                        <Label
                          style="width: 55px; font-size: 12px; height: 43px; margin-top: 8px;"
                        >
                          {$_("classes.cubicSize." + segment)}
                        </Label>
                      </Segment>
                    </SegmentedButton>
                  </div>
                </Content>
              </Panel>
            {/each}
          </Accordion>
        </div>
        <div style="height: 70px; background-color: #fff;"></div>
      </div>
    {/if}

    <div class="buttonArea">
      <button class="backBtn" on:click={confirmDiscardAndGoBack}>戻る</button>
      <button
        class={num == 0 ||
        numberOfScanInProgress > 0 ||
        updateInProgress ||
        !allChecked
          ? "disabledBtn"
          : "confirmBtn"}
        disabled={num == 0 ||
          numberOfScanInProgress > 0 ||
          updateInProgress ||
          !allChecked}
        on:click={loadingProgress.wrapAsync(updateStatuses)}
        >{#if badgeNum > 0}<svelte:component this={badge} {badgeNum} />{/if}
        {#if !updateInProgress}確定{:else}登録中{/if}
      </button>
    </div>
  </main>

  <Footer {confirmDiscardAndChangePage} />
</div>
<div class="subContentsWrapper">
  <!-- 戻るダイアログ -->
  <ConfirmDialog
    bind:this={backConfirmDialog}
    mandatory={true}
    type={ConfirmDialogTypes.OK_CLOSE}
    onDialogClosedHandler={(event) => {
      if (event.detail.action === "ok") {
        functionAfterDiscard();
      }
    }}
  >
    <svelte:fragment slot="title">確認</svelte:fragment>
    <svelte:fragment slot="content"
      >読み込んだ荷物情報は破棄されます。<br />よろしいですか？</svelte:fragment
    >
  </ConfirmDialog>
</div>

<style lang="scss">
  main {
    display: flex;
    flex-direction: column;
  }

  .listContents {
    flex: 1;
    overflow-y: auto;
    background-color: #fff;
  }

  .destinationCenterName {
    background-color: #018786;
    color: #fff;
    font-size: 1.2rem;
    padding: 10px 15px;
  }

  .listItem {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    padding: 8px 0 8px 24px;
    border-bottom: 1px solid #ccc;
  }
  .listItem.listItem-error {
    background-color: #ffe7e7;
  }

  .number {
    width: 80%;
    text-align: left;
    font-size: 16px;

    .quantity {
      color: #c62800;
    }
  }

  .button {
    width: 20%;
  }

  .warningText {
    font-size: 14px;
    margin: 8px 8px 0 0;
    background-color: #ffe7e7;
    color: #c80000;
    border-radius: 4px;
    padding: 3px 4px;
    width: 100%;
    line-height: 1.4;

    :global(.mdc-form-field) {
      color: #c80000;
    }
    .deleteButtonArea {
      margin-top: 8px;
      text-align: right;
    }
  }

  .edit_button {
    padding: 4px 0 2px 8px;
    background-color: #fff;
    border: 1px solid #018786;
    border-radius: 20px;
  }

  .edit_area {
    padding: 10px 0;
  }

  .edit_grid_area {
    margin: 0 0 5px 20px;
    display: grid;
    grid-template-columns: 70% 30%;
    align-items: center;

    .receiverInfo {
      font-size: 14px;
      line-height: 20px;
      padding-right: 10px;
    }

    .highlight {
      padding: 3px;
      border-radius: 4px;
      background-color: #facfcf;
    }

    .note {
      font-size: 11.4px;
      color: #c80000;
    }
  }

  /* ボタンエリア */
  .buttonArea button {
    width: 60px;
    height: 60px;
    border: none;
    border-radius: 50%;
    font-weight: bold;
    color: #fff;
  }

  .backBtn {
    position: fixed;
    font-size: 16px;
    bottom: calc(70px + var(--app-inset-bottom-padding));
    left: 25px;
    padding: 0;
    margin: 0;
    background-color: #018786;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
  }

  .confirmBtn {
    position: fixed;
    font-size: 16px;
    bottom: calc(70px + var(--app-inset-bottom-padding));
    right: 25px;
    padding: 0;
    margin: 0;
    background-color: #018786;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
  }

  .disabledBtn {
    position: fixed;
    font-size: 16px;
    bottom: calc(70px + var(--app-inset-bottom-padding));
    right: 25px;
    padding: 0;
    margin: 0;
    background-color: #ccc;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
  }

  .confirmBtn,
  .disabledBtn {
    :global(.smui-badge.smui-badge--color-primary) {
      background-color: red;
    }
  }
</style>
